/* eslint-disable multiline-ternary */
/* eslint-disable semi */
import authHttp from "./authHttp";

export const getALLProductData = async (value) => {
  // eslint-disable-next-line multiline-ternary
  const url = value.search
    ? `admin/barcodelookup?page=${value.page}&limit=${value.limit}&search=${value?.search}`
    : `admin/barcodelookup?page=${value.page}&limit=${value.limit}`;
  const { data } = await authHttp.get(url);

  return data;
};

export const editProduct = async (barcodeId, value) => {
  const url = `admin/barcodelookup/${barcodeId}`;
  const { data } = await authHttp.put(url, value);
  return data;
};

export const createProduct = async (val) => {
  const url = `admin/barcodelookup`;
  const { data } = await authHttp.post(url, val);
  return data;
};

export const getProductDetails = async (barcodeId) => {
  const url = `admin/barcodelookup/${barcodeId}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const deleteImage = async (val) => {
  const url = "admin/barcodelookup/deleteImage";
  const { data } = await authHttp.del(url, {
    data: {
      barcodeId: val?.barcodeId,
      deleteImage: val?.imageURL,
      type: val?.type,
    },
  });
  return data;
};

export const shortTitleGenerate = async (val) => {
  const url = `admin/barcodelookup/shortTitle/generate`;
  const { data } = await authHttp.post(url, val);
  return data;
};
