/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFailedScan,
  marKDone,
  getAuctions,
  getSuccessScan,
  getCounts,
} from "@src/services/home.services";

import { paginationActions } from "./home";

export const fetchFailedScan = createAsyncThunk(
  "failedScan/post",
  async (value, { dispatch }) => {
    dispatch(paginationActions(value));
    return await getFailedScan(value);
  }
);

export const setMarkDone = createAsyncThunk(
  "marKDone/post",
  async (value, { dispatch }) => {
    const data = {
      id: value.id,
      markdone: value.markdone,
    };
    const failedData = {
      page: value.page,
      limit: value.limit,
      failedBody: value.failedBody,
    };

    await marKDone(data);
    dispatch(fetchFailedScan(failedData));
  }
);

export const fetchAuction = createAsyncThunk(
  "auction/get",
  async (location) => {
    return await getAuctions(location);
  }
);

export const fetchCounts = createAsyncThunk("counts/post", async (value) => {
  return await getCounts(value);
});

export const fetchSuccessScan = createAsyncThunk(
  "successScan/post",
  async (
    value
    // { dispatch }
  ) => {
    // dispatch(fetchCounts(value));
    return await getSuccessScan(value);
  }
);
