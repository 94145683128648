/* eslint-disable comma-dangle */
/* eslint-disable semi */
import authHttp from "./authHttp";

export const getAllAuction = async (locid) => {
  const url = `admin/auction?location=${locid}`;
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};

export const updateAuction = async (payload) => {
  const url = "admin/auction";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const recoverAllAuction = async (auction) => {
  const url = `admin/auction/recover`;
  const { data } = await authHttp.get(url, { params: { auction } });
  return data;
};

export const checkAvailableAuction = async (payload) => {
  const url = `admin/auction/validate/startNo`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
