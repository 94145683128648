/* eslint-disable comma-dangle */
/* eslint-disable semi */
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAuction,
  fetchCounts,
  fetchFailedScan,
  fetchSuccessScan,
} from "./home.thunk";

const initialState = {
  isLoading: false,
  successScanData: [],
  failedScanData: [],
  auctionData: [],
  page: 1,
  rowPerPage: 10,
  error: null,
  activeLocation: [],
  activeAuction: [],
  auctionSelect: [],
  isDownload: {
    value: 0,
    isDownloaded: false,
    fileName: null,
  },
  counts: {
    totalScan: 0,
    successScan: 0,
    failedScan: 0,
    notAddedCsv: 0,
    isLoading: false,
  },
};
const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveAuction: (state, action) => {
      state.activeAuction = action.payload;
    },
    setAuctiondata: (state, action) => {
      state.auctionSelect = action.payload;
    },
    setIsDownload: (state, action) => {
      state.isDownload = action.payload;
    },
    paginationActions(state, action) {
      const { page, limit } = action.payload;
      state.page = page;
      state.rowPerPage = limit;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAuction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAuction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.auctionData = action.payload.data;
      state.error = null;
    });

    builder.addCase(fetchAuction.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.auctionData = [];
    });

    // failedScanData
    builder.addCase(fetchFailedScan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFailedScan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.failedScanData = action.payload;
      state.error = null;
    });
    builder.addCase(fetchFailedScan.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.failedScanData = [];
    });

    // successScanData
    builder.addCase(fetchSuccessScan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSuccessScan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.successScanData = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSuccessScan.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.successScanData = [];
    });

    builder.addCase(fetchCounts.pending, (state) => {
      state.counts.isLoading = true;
    });
    builder.addCase(fetchCounts.fulfilled, (state, action) => {
      state.counts.isLoading = false;
      state.counts = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCounts.rejected, (state, action) => {
      state.counts.isLoading = false;
      state.error = action.error.message;
      state.counts = {
        totalScan: 0,
        successScan: 0,
        failedScan: 0,
        notAddedCsv: 0,
      };
    });
  },
});

export const {
  setActiveLocation,
  setActiveAuction,
  setAuctiondata,
  setIsDownload,
  paginationActions,
} = homeSlice.actions;

export default homeSlice.reducer;
