/* eslint-disable semi */
import axios from "axios";
import authHttp from "./authHttp";

const getGenerateCsvURL = process.env.REACT_APP_SUCCESSFUL_SCAN_URL;

export const getFailedScan = async (value) => {
  const url = `admin/scanreport/failed?page=${value.page}&limit=${value.limit}`;
  const { data } = await authHttp.post(url, value.failedBody);
  return data;
};

export const marKDone = async (body) => {
  const url = "admin/scanreport/markdone";
  const { data } = await authHttp.post(url, body);
  return data;
};

export const getAuctions = async (location) => {
  const url = `user/auction?location=${location}&isAdmin=true`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getFailedPrint = async (body) => {
  const url = "admin/scanreport/failed?all=all";
  const { data } = await authHttp.post(url, body);
  return data;
};

export const getSuccessScan = async (value) => {
  const url = `${process.env.REACT_APP_SUCCESSFUL_SCAN_URL}/admin/scanreport/success`;
  const { data } = await axios.post(url, value);
  return data;
};

export const getGenerateCsv = async (value) => {
  const url = `${getGenerateCsvURL}/admin/scanreport`;
  const { data } = await axios.post(url, value);
  return data;
};

export const getHistoryData = async (value) => {
  const url = `admin/history/${value}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const consolidatedData = async (value) => {
  const url = `${process.env.REACT_APP_SUCCESSFUL_SCAN_URL}/admin/scanreport/consolidatecsv`;
  const { data } = await axios.post(url, value);
  return data;
};

export const getCounts = async (value) => {
  const url = `${process.env.REACT_APP_SUCCESSFUL_SCAN_URL}/admin/scanreport/count`;
  const { data } = await axios.post(url, value);
  return data;
};

export const resetCSV = async (value) => {
  const url = `${process.env.REACT_APP_SUCCESSFUL_SCAN_URL}/admin/scanReport/revert/csvgeneration`;
  const { data } = await axios.post(url, value);
  return data;
};
// export const getDownloadCsv = async (value) => {
//   const exportUrl = `/admin/scanreport?location=${value.location}&scrapperId=${value.scrapperId}&isUploaded=${value.isNewUploaded}`;

//   await authHttp.get(exportUrl, {responseType: 'blob'})
//     .then((res) => {
//       const url = window.URL.createObjectURL(new Blob([res.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', value.fileName);
//       document.body.appendChild(link);
//       link.click();
//     });
// };
